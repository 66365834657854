import { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import dynamic from 'next/dynamic'
import Head from 'next/head'

const Error404 = dynamic(() => import('../src/screens/Errors/Error404'))

const Custom404: NextPage = () => {
  return (
    <div>
      <Head>
        <title>SpotMe</title>
        <meta name="description" content="SpotMe" />
        <link rel="icon" href="/logos/Logo_Grande.ico" />
      </Head>

      <main>
        <Error404 />
      </main>
    </div>
  )
}

export default Custom404

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const translations = await serverSideTranslations(locale ?? 'es', ['nav'])
  return { props: { ...translations } }
}
